<template>
  <div class="search">
    <div class="search_box">
      <div class="box">
        <div class="content1">{{ pleaseSearch }}</div>
        <div class="content2">
          通过关键词为您找到 <span>{{ searchesNum }}</span> 个内容
        </div>
      </div>
    </div>

    <div class="newsList1">
      <div class="box m160">
        <div class="list" v-for="(item, index) in new_list" :key="index">
          <router-link :to="'/news/newsInfo?id=' + item.aid">
            <div class="flexBetween">
              <div class="boxLeft flexStart">
                <div>{{ (item.add_time * 1000) | dateFmt("DD") }}</div>
                <p>{{ (item.add_time * 1000) | dateFmt("YYYY-MM") }}</p>
              </div>
              <div class="boxCenter">
                {{ item.title }}
              </div>
              <div class="boxRight flexEnd">
                <p>
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="14"
                    height="14"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "contact",
  components: {},
  data() {
    return {
      isHeader: true,
      pleaseSearch: "请搜索...",
      searchesNum: 0,
      new_list: [],
    };
  },
  created() {
    // this.pleaseSearch = this.$route.params.pk_refinfo;
    // console.log(this.$route.params.pk_refinfo);
  },
  mounted() {
    this.$bus.on("lowerRight", (value) => {
      // sessionStorage.setItem("insuranceCode", JSON.stringify(value));
      // const data_ = JSON.parse(sessionStorage.getItem("insuranceCode"));

      this.new_list = value.news_list;
      this.searchesNum = value.total;
      this.pleaseSearch = value.title;

      // console.log(data_);
    });
  },
};
</script>
<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;

.search_box {
  margin-top: 0.74rem;
  background: #eeeeee;
  .box {
    text-align: center;
    padding: 1.2rem 0;
    .content1 {
      font-size: 0.56rem;
      color: #333;
    }
    .content2 {
      font-size: 0.28rem;
      margin-top: 0.16rem;
      span {
        color: @color;
      }
    }
  }
}
.newsList1 {
  margin-top: 0.6rem;
  margin-bottom: 0.9rem;
  .box {
    .list {
      padding: 0.78rem 0;
      border-bottom: 1px solid #ddd;
      a {
        display: block;
        .boxLeft {
          width: 20%;
          font-family: @gilroy_r;
          line-height: 0.6rem;
          padding-top: 0.12rem;
          div {
            font-size: 0.52rem;
            color: #333;
            margin-right: 0.32rem;
          }
          p {
            font-size: 0.22rem;
            color: #999;
          }
        }
        .boxCenter {
          font-family: @albb_m;
          font-size: 0.26rem;
          color: #000;
          line-height: 0.4rem;
          width: 50%;
          text-align: justify;
          transition: 0.4s;
        }
        .boxRight {
          width: 30%;
          p {
            width: 0.6rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            border-radius: 50%;
            background: @color;
          }
        }
        &:hover {
          .boxCenter {
            color: @color;
          }
        }
      }
    }
  }
}
</style>
